.card-login {
	width: 75%;
	border-radius: 10px 10px 10px 10px;
	-moz-border-radius: 10px 10px 10px 10px;
	-webkit-border-radius: 10px 10px 10px 10px;
	border: 0px solid #000000;
	-webkit-box-shadow: 2px 2px 16px -2px rgba(0, 0, 0, 0.16);
	-moz-box-shadow: 2px 2px 16px -2px rgba(0, 0, 0, 0.16);
	box-shadow: 2px 2px 16px -2px rgba(0, 0, 0, 0.16);
	background-color: white
}

div{
    font-family: 'Poppins', sans-serif !important; 
}

.box-img-login{
	width: auto;
    height: 300px;
    
}

